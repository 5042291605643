import {AbstractControl, ValidatorFn} from "@angular/forms";
import {ValidatorEnum} from "../enum";
import {DateTimeFormat} from "@amlCore/components";
import {InnType} from "@amlCore/models";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct";
import moment from "moment";

/**
 * Свой валидатор
 */
export class CustomValidator {

  /**
   * Паттерн для даты с учетом високосных годов
   */
  static datePattern = /^(((0[1-9]|[12]\d|3[01]).(0[13578]|1[02]).((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30).(0[13456789]|1[012]).((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8]).02.((19|[2-9]\d)\d{2}))|(29.02.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;
  static timePattern = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/g;
  static timeSecPattern = /\d{2}:\d{2}:\d{2}/g;
  /**
   * Список исключений ОГРН
   */
  static exclusionOGRN = [
    '1021300507031',
    '1022301969685',
    '1036301840258',
    '1038600004023',
    '1024240680091',
    '1036300880123',
    '1026200853616',
    '1036300220134',
    '1036300220365',
    '1034701531564',
    '1020502532127',
    '1034701531597',
    '1036300220123',
    '1036300220299',
    '1027100980032',
    '1026303277850',
    '1106375000012',
    '1026303277992',
    '1036303050050',
    '1073114000430',
    '1036301840291'
  ];
  /**
   * Свои валидаторы
   * @param type - тип валидатора
   * @param param - параметры валидатора
   */
  static validator(type: ValidatorEnum, param?: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: CustomValidatorError } | null => {
      if (!control.value) {
        switch (type) {
          case ValidatorEnum.REGION:
            return this.validatorRegion(control);
          default:
            return null;
        }
      }
      switch (type) {
        case ValidatorEnum.DATEPATTERN:
          return this.validatorDate(control);
        case ValidatorEnum.DATEPATTERNORZERO:
          return this.validatorDateOrZero(control);
        case ValidatorEnum.DATERANGE:
          return this.validatorDateRange(control, param);
        case ValidatorEnum.TIMEPATTERN:
          return this.validatorTime(control, param);
        case ValidatorEnum.INN:
          return this.validatorINN(control, param);
        case ValidatorEnum.OKPO:
          return this.validatorOKPO(control);
        case ValidatorEnum.OGRN:
          return this.validatorOGRN(control);
        case ValidatorEnum.SNILS:
          return this.validatorSNILS(control);
        case ValidatorEnum.OKTMO:
          return this.validatorOKTMO(control);
        case ValidatorEnum.BIKKO:
          return this.validatorBIKKO(control);
        case ValidatorEnum.PolicyOMC:
          return this.validatorPolicyOMC(control);
        case ValidatorEnum.PolicyOMC9or16:
          return this.validatorPolicyOMC9or16(control);
        case ValidatorEnum.InnKio5or10:
          return this.validatorInnKio5or10(control);
        case ValidatorEnum.IdentifikatorPD28or30:
          return this.validatorIdentifikatorPD28or30(control);
        case ValidatorEnum.CORRID:
          return this.validatorCORRID(control);
        case ValidatorEnum.Swift2or8or11:
          return this.validatorSwift2or8or11(control);
        default:
          return null;
      }
    };
  }

  static validatorRegion(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
    const value = control.value;
    if (control.parent) {
      const country = control.parent.get('country');
      const region = control.parent.get('region');
      if (!region.value && country.value && country.value.code === "643") {
        return {
          customValidator: {
            errorMsg: 'При страна РФ поле обязательное',
            withoutSubmitted: true
          } as CustomValidatorError
        };
      }
    }
    return null;
  }

  /**
   * Валидатор даты по паттерну
   */
  static validatorDate(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
    const value = control.value;
    if (!value.match(this.datePattern)) {
      return {
        customValidator: {
          errorMsg: 'Введенная дата некорректна',
          withoutSubmitted: true
        } as CustomValidatorError
      };
    }
    return null;
  }

  /**
   * Валидатор: дата по паттерну или 0
   */
  static validatorDateOrZero(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
    const value = control.value;
    if (!value.match(this.datePattern) && value !== '0') {
      return {
        customValidator: {
          errorMsg: 'Введенная дата некорректна',
          withoutSubmitted: true
        } as CustomValidatorError
      };
    }
    return null;
  }

  /**
   * Валидатор даты по диапазону
   */
  static validatorDateRange(control: AbstractControl, dateRange : [NgbDateStruct, NgbDateStruct]): { [key: string]: CustomValidatorError } | null {
    const value = moment(control.value, "DD.MM.YYYY");
    if(!value) return null;
    if(dateRange && dateRange[0]){
      const date = moment().month(dateRange[0].month-1).date(dateRange[0].day).year(dateRange[0].year)
        if(value.isBefore(date.startOf('day'))){
          return {
            customValidator: {
              errorMsg: `Минимально допустимая дата ${date.format("DD.MM.YYYY")}`,
              withoutSubmitted: true
            } as CustomValidatorError
          };
        }
    }
    if(dateRange && dateRange[1]){
      const date = moment().month(dateRange[1].month-1).date(dateRange[1].day).year(dateRange[1].year)
      if(value.isAfter(date.startOf('day'))){
        return {
          customValidator: {
            errorMsg: `Максимально допустимая дата ${date.format("DD.MM.YYYY")}`,
            withoutSubmitted: true
          } as CustomValidatorError
        };
      }
    }
    return null;
  }

  /**
   * Валидатор время по паттерну
   */
  static validatorTime(control: AbstractControl, typeDate: DateTimeFormat = 'DD.MM.YYYY HH:MM:SS'):
    { [key: string]: CustomValidatorError } | null {
    const value = control.value;
    let regex: RegExp;
    switch (typeDate) {
      case "DD.MM.YYYY HH:MM":
        regex = this.timePattern;
        break;
      case "DD.MM.YYYY HH:MM:SS":
        regex = this.timeSecPattern;
        break;
    }
    if (!value.match(regex)) {
      return {
        customValidator: {
          errorMsg: 'Введенное время некорректно',
          withoutSubmitted: true
        } as CustomValidatorError
      };
    }
    return null;
  }

  /**
   * Валидатор инн
   */
  static validatorINN(control: AbstractControl, innType: InnType = true): { [key: string]: CustomValidatorError } | null {
    const value = control.value;
    let error = false;
    let errorMsgBig;
    if(['INN_10', 'INN_KIO', true].includes(innType) && (value.length === 5 || value.length === 10) && value.match(/^0+$/)){
      error = true;
      errorMsgBig = 'Поле не должно содержать 5 или 10 нулей.';
    } else if (value.length === 5 && innType === 'INN_KIO') {
      error = false;
    } else if (value.length === 10 && ['INN_10', 'INN_KIO', true].includes(innType)) {
      const dgt10 = String(((
        2 * value[0] + 4 * value[1] + 10 * value[2] +
        3 * value[3] + 5 * value[4] + 9 * value[5] +
        4 * value[6] + 6 * value[7] + 8 * value[8]) % 11) % 10);
      if (value[9] !== dgt10) {
        error = true;
        errorMsgBig = 'Не сходятся контрольные цифры';
      }
    } else if (value.length === 12 && ['INN_12', true].includes(innType)) {
      if(value.match(/^0+$/)){
        error = true;
        errorMsgBig = 'Поле не должно содержать 12 нулей.';
      } else{
        const dgt11 = String(((
            7 * value[0] + 2 * value[1] + 4 * value[2] +
            10 * value[3] + 3 * value[4] + 5 * value[5] +
            9 * value[6] + 4 * value[7] + 6 * value[8] +
            8 * value[9]) % 11) % 10);
        const dgt12 = String(((
            3 * value[0] + 7 * value[1] + 2 * value[2] +
            4 * value[3] + 10 * value[4] + 3 * value[5] +
            5 * value[6] + 9 * value[7] + 4 * value[8] +
            6 * value[9] + 8 * value[10]) % 11) % 10);
        if (value[10] !== dgt11 || value[11] !== dgt12) {
          error = true;
          errorMsgBig = 'Не сходятся контрольные цифры';
        }
      }
    } else if (value.length > 0 && innType === 'INN_KIO') {
      error = true;
      errorMsgBig = 'Поле должно быть не заполнено или должно содержать 5 или 10 символов и содержать корректные данные.';
    } else if (value.length !== 12 && innType === 'INN_12') {
      error = true;
      errorMsgBig = 'Поле должно быть не заполнено или должно содержать 12 символов и содержать корректные данные.';
    } else if (value.length !== 12 && value.length !== 10 && value.length !== 5 && innType === 'INN_12_10_5') {
      error = true;
      errorMsgBig = 'Поле должно быть не заполнено или должно содержать 5, 10 или 12 символов и содержать корректные данные.';
    }
    if (error) {
      return {
        customValidator: {
          errorMsg: 'Неверно заполнено поле',
          errorMsgBig,
          withBigMsg: true,
          withoutSubmitted: true
        } as CustomValidatorError
      };
    }
    return null;
  }

  /**
   * Провекра OKPO
   */
  static validatorOKPO(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
    const value = control.value;
    let sum = 0;
    let error = true;
    // 8 - для любых организаций, кроме ИП
    // 10 – для ИП
    if (value.length === 10 || value.length === 8) {
      for (let idx = 1; idx < value.length; idx++) {
        sum = sum + idx * Number(value.charAt(idx - 1));
      }
      let code = sum % 11;
      if (code === 10) {
        sum = 0;
        for (let idx = 1; idx < value.length - 1; idx++) {
          sum = sum + (idx + 2) * Number(value.charAt(idx - 1));
        }
        const multiplier = value.length === 8 ? 9 : 1;
        sum = sum + multiplier * Number(value.charAt(value.length - 2));
        code = sum % 11;
        if (code === 10) {
          code = 0;
        }
      }
      if (Number(value.charAt(value.length - 1)) === code) {
        error = false;
      }
    }
    if (error) {
      return {
        customValidator: {
          errorMsg: 'Неверно заполнено поле',
          errorMsgBig: 'Не сходятся контрольные цифры',
          withBigMsg: true,
          withoutSubmitted: true
        } as CustomValidatorError
      };
    }
    return null;
  }

  /**
   * Провекра ОГРН и ОГРНИП
   */
  static validatorOGRN(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
    const value = control.value.toString();
    let error = true, errorMsgBig;
    if (value.length === 13 || value.length === 15) {
      // Исключаем ОГРН из проверки
      if (CustomValidator.exclusionOGRN.includes(value)) {
        error = false;
      } else if(value.match(/^0+$/)){
        error = true;
        errorMsgBig = `Поле не должно содержать ${value.length} нулей`;
      } else {
        // контрольная цифра равна остатку от деления на 11 (или на 13) числа,
        // состоящего из первых 12 (или 14) цифр. Если остаток больше 9, то берем последнюю цифру у остатка
        const cont = (Number(value.slice(0, -1)) % (value.length - 2)).toString().slice(-1);
        if (cont === value.slice(-1)) {
          error = false;
        }
      }
    }
    if (error) {
      return {
        customValidator: {
          errorMsg: 'Неверно заполнено поле',
          errorMsgBig: errorMsgBig || 'Не сходятся контрольные цифры',
          withBigMsg: true,
          withoutSubmitted: true
        } as CustomValidatorError
      };
    }
    return null;
  }

  /**
   * Провекра СНИЛС
   * Источник википедия.
   * Страховой номер индивидуального лицевого счёта страхового
   * свидетельства обязательного пенсионного страхования (он же СНИЛС) проверяется на
   * корректность контрольным числом. СНИЛС имеет вид: «XXX-XXX-XXX YY»,
   * где XXX-XXX-XXX — собственно номер, а YY — контрольное число.
   * Алгоритм формирования контрольного числа СНИЛС таков:

    1 Проверка контрольного числа Страхового номера проводится только для номеров больше номера 001-001-998
    2 Контрольное число СНИЛС рассчитывается следующим образом:
      1 Каждая цифра СНИЛС умножается на номер своей позиции (позиции отсчитываются с конца)
      2 Полученные произведения суммируются
      3 Если сумма меньше 100, то контрольное число равно самой сумме
      4 Если сумма равна 100 или 101, то контрольное число равно 00
      5 Если сумма больше 101, то сумма делится по остатку на 101 и контрольное число определяется остатком от деления аналогично пунктам 2.3 и 2.4
   */
  static validatorSNILS(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
    const getCheckSum = (digits: string): number => {
      let sum = 0;
      for (let i = 0; i < 9; i++) {
        sum += parseInt(digits.charAt(i), 10) * (9 - i);
      }
      return sum;
    };

    let value = control.value.toString();
    let error = false;
    let errorMsgBig;
    // минимальное количество символов введеное для проверки контрольной суммы
    const minLengthDigits = 14;

    if (value.length === minLengthDigits) {
        value = value.replace(/[- ]/g, '');
        // контрольная сумма
        let checkDigit = parseInt(value.substring(9, 11), 10);
        // текущий снилс введеный пользователем для проверки
        const currentCheckSumm = getCheckSum(value.substring(0, 9));
        // число минимальной границы, по которой определяются допустим ли введеный СНИЛС для проверки
        const defaultMinTarget = getCheckSum('001001998');
        // проверка П1 алгоритма. проверяем что контрольная сумма меньше минимального значения для проверки.
        if (currentCheckSumm <= defaultMinTarget) {
          error = true;
          errorMsgBig = 'Страховой номер должен быть больше 001-001-998';
        } else {
            let sum = 0;
            // Расчитываем сумму для проверки шаг 2.1, 2.2 из алгоритма
            sum = getCheckSum(value.substring(0, 9));

            if (sum < 100) {
              checkDigit = sum;
            } else if (sum > 101) {
              checkDigit = sum % 101;
              if (checkDigit === 100) {
                checkDigit = 0;
              }
            }

            if (checkDigit !== parseInt(value.substring(9, 11), 10)) {
              error = true;
              errorMsgBig = 'Не сходятся контрольные цифры';
            }
        }
    } else if (value.length > 0) {
      error = true;
      errorMsgBig = 'Неверный формат поля. Требуется формат 000-000-00 00';
    }
    if (error) {
      return {
        customValidator: {
          errorMsg: 'Неверно заполнено поле',
          errorMsgBig,
          withBigMsg: true,
          withoutSubmitted: true
        } as CustomValidatorError
      };
    }
    return null;

    /**
     * Переворачиваем строку
     */
    function revert(str: string) {
      let newStr = '';
      for (let i = str.length - 1; i >= 0; i--) {
        newStr += str.charAt(i);
      }
      return newStr;
    }
  }

  /**
   * Проверка БИККО
   */
  static validatorBIKKO(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
    const value = control.value.toString();
    if (value.length > 0 && !(value.length === 2 || value.length === 8 || value.length === 9 || value.length === 11)) {
      return {
        customValidator: {
          errorMsg: 'Неверно заполнено поле',
          errorMsgBig: 'БИККО указан неверно, реквизит должен состоять из 2 или 8, или 9, или 11 цифр',
          withBigMsg: true,
          withoutSubmitted: true
        } as CustomValidatorError
      };
    }
    return null;
  }

  /**
   * Проверка OKTMO
   */
  static validatorOKTMO(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
    const value = control.value.toString();
    if (value.length > 0 && !(value.length === 8 || value.length === 11)) {
      return {
        customValidator: {
          errorMsg: 'Неверно заполнено поле',
          errorMsgBig: 'ОКТМО указан неверно, реквизит должен состоять из 8 или 11 цифр',
          withBigMsg: true,
          withoutSubmitted: true
        } as CustomValidatorError
      };
    }
    return null;
  }

  /**
   * Проверка полиса ОМС
   */
  static validatorPolicyOMC(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
    const value = control.value.toString();
    let error = false;
    if (value.length !== 16) {
      error = true;
    } else {
      let odd = '', even = '';
      for (let i = 14; i >= 0; i--) {
        const digit = parseInt(value.charAt(i), 10);
        if(i % 2 != 0){
          even += digit;
        } else {
          odd += digit;
        }
      }

      const join = `${even}${parseInt(odd) * 2}`;
      let summ = 0;
      for (let i = 0; i < join.length; i++) {
        summ += parseInt(join.charAt(i));
      }
      if ((10 - summ % 10) % 10 != parseInt(value.charAt(15))) {
        error = true;
      }
    }
    if(error){
      return {
        customValidator: {
          errorMsg: 'Неверно заполнено поле',
          errorMsgBig: 'Поле должно содержать корректные данные',
          withBigMsg: true,
          withoutSubmitted: true
        } as CustomValidatorError
      };
    }
    return null;
  }

/**
   * Проверка полиса ОМС
   */
 static validatorPolicyOMC9or16(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
  const value = control.value.toString();
  const error = value.length !== 9 && value.length !== 16;
  if(error){
    return {
      customValidator: {
        errorMsg: `Поле должно содержать 9 либо 16 цифр, сейчас - ${value.length}`,
        withBigMsg: false,
        withoutSubmitted: false
      } as CustomValidatorError
    };
  }
  return null;
}

/**
   * Проверка ИНН(КИО)
   */
static validatorInnKio5or10(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
  const value = control.value.toString();
  const error = value.length !== 5 && value.length !== 10;
  if(error){
    return {
      customValidator: {
        errorMsg: `Поле должно содержать 5 или 10 цифр, сейчас - ${value.length}`,
        withBigMsg: false,
        withoutSubmitted: false
      } as CustomValidatorError
    };
  }
  return null;
}

/**
   * Проверка Идентификаторa подозрительной деятельности
   */
static validatorIdentifikatorPD28or30(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
  const value = control.value.toString();
  const error = value.length !== 28 && value.length !== 30;
  if(error){
    return {
      customValidator: {
        errorMsg: `Поле должно содержать 28 или 30 цифр, сейчас - ${value.length}`,
        withBigMsg: false,
        withoutSubmitted: false
      } as CustomValidatorError
    };
  }
  return null;
}


  /**
   * Проверка Идентификатор ЛК
   */
  static validatorCORRID(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
    const value = control.value.toString();
    if (value.length > 0 && (Number(value) < 2000007570 || Number(value) > 2147483647)) {
      return {
        customValidator: {
          errorMsg: 'Значение должно быть в диапазоне чисел от 2000007570 до 2147483647.',
          errorMsgBig: 'Идентификатор корреспондента указан неверно, значение должно быть в диапазоне чисел от 2000007570 до 2147483647.',
          withBigMsg: true,
          withoutSubmitted: true
        } as CustomValidatorError
      };
    }
    return null;
  }

  static dateNotGreaterThan(dateField1: string, dateField2: string, validatorField: { [key: string]: boolean }): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const date1 = c.get(dateField1).value;
      const date2 = c.get(dateField2).value;
      if ((date1 !== null && date2 !== null) && moment(date1, "DD.MM.YYYY hh:mm").isAfter(moment(date2, "DD.MM.YYYY hh:mm"))) {
        return validatorField;
      }
      return null;
    };
  }

  /**
   * Проверка времени относительно текущего момента
   * beforeMoment = true - время д. б. до текущего момента
   * beforeMoment = false - время д. б. после текущего момента
   */
   static dateTimeRange(dateField: string, timeField: string, beforeMoment: boolean): ValidatorFn {
    return (c: AbstractControl):  { [key: string]: CustomValidatorError } | null => {
      const dateCntr = c.get(dateField);
      const timeCntr= c.get(timeField);
      const date = dateCntr.value;
      const time = timeCntr.value;

      if ((date !== null && time !== null)){
	   const dateTime = moment(`${date} ${time}`, "DD.MM.YYYY hh:mm:ss");
       if ((beforeMoment && dateTime.isAfter(moment()) || !beforeMoment && dateTime.isBefore(moment()))) {
         const error = {
	        customValidator: {
	          errorMsg: 'Введенное время некорректно',
	          withoutSubmitted: true
	        } as CustomValidatorError};
         timeCntr.setErrors(error);
       }
      }
      return null;
    };
  }

  static validatorSwift2or8or11(control: AbstractControl): { [key: string]: CustomValidatorError } | null {
    const value = control.value.toString();
    const error = value.length !== 2 && value.length !== 8 && value.length !== 11;
    if(error){
      return {
        customValidator: {
          errorMsg: `Поле должно содержать 2, 8 или 11 цифр, сейчас - ${value.length}`,
          withBigMsg: false,
          withoutSubmitted: false
        } as CustomValidatorError
      };
    }
    return null;
  }
}

/**
 * Интерфейс сообщений об ошибке
 */
export interface CustomValidatorError {
  errorMsg: string; // Ошибочное сообщение (краткое)
  withBigMsg?: boolean; // true - показывать подробное сообщение
  errorMsgBig?: string; // Ошибочное сообщение (подробное)
  withoutSubmitted: boolean; // true если показыать ошибку сразу (без submitted)
}

/**
 * Типы возможных валидаций
 */
export enum ValidatorEnum {
  DATEPATTERN = 'datePattern',
  DATEPATTERNORZERO = 'datePatternOrZero',
  DATERANGE = 'dateRange',
  TIMEPATTERN = 'timepattern',
  INN = 'inn',
  OKPO = 'okpo',
  OGRN = 'ogrn',
  SNILS = 'snils',
  BIKKO = 'BIKKO',
  REGION = 'region',
  OKTMO = 'oktmo',
  PolicyOMC = 'PolicyOMC',
  PolicyOMC9or16 = 'PolicyOMC9or16',
  IdentifikatorPD28or30 = 'IdentifikatorPD28or30',
  InnKio5or10 = 'InnKio5or10',
  CORRID = 'isCorrID',
  Swift2or8or11 = 'Swift2or8or11',
}

import {Component, OnInit, OnDestroy} from '@angular/core';

import {
  SharedMenuService,
  TableService,
  UserService,
  ProfileService
} from "@amlCore/services";

import {Router} from '@angular/router';
import {Auth, NewOrganizationModel, OrganizationModel} from "@amlCore/models";
import {faEye, faSignInAlt, faSignOutAlt,
  faIdBadge, faUser, faBars, faAddressCard,
  faMailBulk, faClipboardList, faClipboardCheck,
  faCreditCard, faUserCheck, faBook, faCaretDown, faThumbtack} from '@fortawesome/free-solid-svg-icons'
import {Subject, Unsubscribable} from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ConfirmComponent} from "@amlCore/modals";

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private PINNED_DROPDOWN_CLASS = 'thumbtack-pinned';
  private UNPINNED_DROPDOWN_CLASS = 'thumbtack-unpinned';
  private sharedMenuSubscribe: Unsubscribable;
  private _login = '';
  public isVisibleDropdownMenu = false;
  public caretClass = 'aml-caret-up';
  public hasNoticeToBeResolve: boolean;
  public hasCheckClientResolve: boolean;
  public hasCheckTransactionResolve: boolean;
  public pinnedDropdownClass = "";
  public isOpenDictionaryMenu = false;
  public menuTitle = "";
  public popoverOptions = {
    place: "right",
    description: `Нажмите на кнопку, если необходимо
                  закрепить меню в левой части окна`,
    trigger: "mouseenter:mouseleave"
  };
  orgInfo: NewOrganizationModel = null;
  public showMenu = false;

  constructor(private userService: UserService,
              private router: Router,
              private modalService: NgbModal,
              private menuSrv: SharedMenuService,
              private tableSrv: TableService,
              public profileService: ProfileService) {
  }
  icons = {
    brand: faEye,
    sign: faSignInAlt,
    requisites: faIdBadge,
    user: faUser,
    exit: faSignOutAlt,
    btnBurger: faBars,
    addressCard: faAddressCard,
    book: faBook,
    alerts: faMailBulk,
    eye: faEye,
    transactionsList: faClipboardList,
    transactionsCheck: faClipboardCheck,
    spkCheck: faUserCheck,
    caret: faCaretDown,
    thumbtack: faThumbtack,
    creditCard: faCreditCard
  };
  currentUser: Auth;
  public userRoleDesc: string = sessionStorage.getItem('userRole');
  ngOnInit(): void {
    this.userService.showMenu$.subscribe((data) => {
      this.showMenu = data;
    })
    this.profileService.userRoleDesc$.subscribe((data) => {
      this.userRoleDesc = data;
    })
    this.sharedMenuSubscribe = this.tableSrv.sharedMenu().getSubscribe().subscribe((title) => {
      if (typeof title === "string") {
        this.menuTitle = title;
      }
    });

    this.menuSrv.getSubscribe().subscribe((isPinned) => {
      if (typeof isPinned === "boolean") {
        this.isVisibleDropdownMenu = !isPinned;
      }
    });

    this.userService.isAuthenticated.subscribe(
      (isAuthenticated) => {
        this._login = this.userService.getAuthenticateUser.login;
        if (!isAuthenticated) {
          if (this._login) {
            sessionStorage.setItem(`isPinnedMenuNav-${this._login}`, "true");
          }
          this.router.navigateByUrl('/');
        }
        this.isVisibleDropdownMenu = !JSON.parse(sessionStorage.getItem(`isPinnedMenuNav-${this._login}`));
      });
    this.userService.currentUser.subscribe((userData) => {
      this.currentUser = userData;
    });
    this.userService.orgInfo.subscribe((data: NewOrganizationModel) => {
      this.orgInfo = data;
    });

    const isPinned = !!JSON.parse(sessionStorage.getItem(`isPinnedMenuNav-${this._login}`));
    this.menuSrv.emit(isPinned);

    this.setClassPinned();
  }

  public openDictionarySubMenu(): void {
    this.isOpenDictionaryMenu = !this.isOpenDictionaryMenu;
    this.changeCaretClass(this.isOpenDictionaryMenu);
    this.setClassPinned();
  }

  // метод меняющи css классы для прибитого и не прибитого отображения скрепки
  public setClassPinned(): void {
    const isPinned = Boolean(JSON.parse(sessionStorage.getItem(`isPinnedMenuNav-${this._login}`)));
    if (isPinned) {
      this.pinnedDropdownClass = this.PINNED_DROPDOWN_CLASS;
    } else {
      this.pinnedDropdownClass = this.UNPINNED_DROPDOWN_CLASS;
      this.setScroll();
    }
  }

  // метод переключающий флаг togglePinned для скрепки
  public changeStatePinned(): void {
    const isPinned = !Boolean(JSON.parse(sessionStorage.getItem(`isPinnedMenuNav-${this._login}`)));
    sessionStorage.setItem(`isPinnedMenuNav-${this._login}`, (isPinned) + '');
    this.menuSrv.emit(isPinned);
    this.setClassPinned();
  }


  public goHome(): void {
    this.router.navigateByUrl('/');
  }

  public exit(): void {
    const modal = this.modalService.open(ConfirmComponent);
    modal.componentInstance.text = "Вы точно хотите выйти из кабинета?";
    modal.componentInstance.isOutputText = false;
    modal.result.then(

        data => this.userService.logout().subscribe(() =>
            {
              this.userRoleDesc = null;
              this.userService.clearAuth();
              location.reload();
            }
        ),
        cancel => {return; });
  }

  private changeCaretClass(isOpen: boolean): void {
    if (isOpen) {
      this.caretClass = 'aml-caret-down';
    } else {
      this.caretClass = 'aml-caret-up';
    }
  }

  ngOnDestroy(): void {
    this.sharedMenuSubscribe.unsubscribe();
  }

  /**
  * Установка скролла  для меню
  */
  setScroll() {
    if (document.getElementById('navscroll')) {
      const elem = document.getElementById('navscroll');
      setTimeout(() => {
        const elem = document.getElementById('navscroll');
        elem.scrollTop = +localStorage.getItem('nav-scroll');
      }, 0);
      elem.addEventListener('scroll', () => {
        localStorage.setItem('nav-scroll', String(elem.scrollTop));
      });
    }
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-container {
  width: -moz-fit-content;
  width: fit-content;
  max-width: 700px;
  padding: 0.75rem !important;
}

.icon-size {
  font-size: 1.4rem !important;
}

.text {
  cursor: pointer;
}

.block {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/core/components/alert/alert.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EAAA,kBAAA;EACA,gBAAA;EACA,2BAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".alert-container {\n  width: fit-content;\n  max-width: 700px;\n  padding: 0.75rem !important;\n}\n\n.icon-size {\n  font-size: 1.4rem !important;\n}\n\n.text {\n  cursor: pointer;\n}\n\n.block {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {ValidatorFn} from "@angular/forms";
import {DateTimeFormat} from "@amlCore/components";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct";

export class ValidatorParamsModel {
  min?: number;
  max?: number;
  minNumber?: number;
  maxNumber?: number;
  isReq?: boolean;
  isEmail?: boolean;
  pattern?: string | RegExp;
  extendedValidation?: Array<ValidatorFn>;
  isDate?: boolean;
  isDateOrZero?: boolean;
  dateRange?: [NgbDateStruct, NgbDateStruct];
  isTime?: DateTimeFormat;
  isINN?: InnType;
  isOKPO?: boolean;
  isOGRN?: boolean;
  isSNILS?: boolean;
  isOKTMO?: boolean;
  isBIKKO?: boolean;
  isPolicyOMC?: boolean;
  isPolicyOMC9or16?: boolean;
  isRegion?: boolean;
  isCorrID?: boolean;
  isInnKio5or10?: boolean;
  isIdentifikatorPD28or30?: boolean;
  isSwift2or8or11?: boolean;

  constructor(object: ValidatorParamsModel) {
    this.min = object.min;
    this.max = object.max;
    this.maxNumber = object.maxNumber;
    this.minNumber = object.minNumber;
    this.isReq = object.isReq;
    this.isEmail = object.isEmail;
    this.pattern = object.pattern;
    this.extendedValidation = object.extendedValidation;
    this.isDate = object.isDate;
    this.isDateOrZero = object.isDateOrZero;
    this.dateRange = object.dateRange;
    this.isTime = object.isTime;
    this.isINN = object.isINN;
    this.isOKPO = object.isOKPO;
    this.isOGRN = object.isOGRN;
    this.isBIKKO = object.isBIKKO;
    this.isSNILS = object.isSNILS;
    this.isOKTMO = object.isOKTMO;
    this.isPolicyOMC = object.isPolicyOMC;
    this.isRegion = object.isRegion;
    this.isCorrID = object.isCorrID;
    this.isInnKio5or10 = object.isInnKio5or10;
    this.isIdentifikatorPD28or30 = object.isIdentifikatorPD28or30;
  }
}

/**
 * Тип длинны инн для валидации:
 * INN_KIO - для полей ИНН/КИО
 * INN_10 - для ЮР лиц
 * INN_12 - для ФЛ и ИП
 * INN_12_10_5 - для ИНН 12 или 10 или 5 длины
 * deprecated: true - определяется автоматически
 */
export type InnType = 'INN_KIO' | 'INN_10' | 'INN_12' | 'INN_12_10_5' | true;


import {Injectable} from '@angular/core';
import {ValidatorParamsModel} from '@amlCore/models';
import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {CustomValidator} from "@amlCore/utils";
import {ValidatorEnum} from "@amlCore/enums";
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ValidatorService {
  validSingleControlDocument = {
    // priznFTr: this.getValidation({ isReq: true, max: 1, minNumber: 0, maxNumber: 5 }),
    // dopKodOperatsii: this.getValidation({ max: 100 }),
    priznNeobOperatsii: this.getValidation({ isReq: true, min: 4, max: 4 }),
    telefonNomer: this.getValidation({ max: 50 }),
    nomerSchetSejf: this.getValidation({ isReq: true, max: 34 }),
    podpunkt: this.getValidation({ isReq: true, max: 250 }),
    operatsiiaPerechen: this.getValidation({ isReq: true, min: 30, max: 30, pattern: '20[1-9][0-9]_[0-9]{4}_[0-9]{4}_0(1|[3-6]|9)_[0-9]{12}' }),
  } as const;

  getValidation(p: ValidatorParamsModel | string): ValidatorFn[] {
    if (typeof p === 'string') return this.validSingleControlDocument[p];
    const validator: ValidatorFn[] = [];
    if (p) {
      if (p.max) {
        validator.push(Validators.maxLength(p.max));
      }
      if (p.min) {
        validator.push(Validators.minLength(p.min));
      }
      if (p.maxNumber) {
        validator.push(Validators.max(p.maxNumber));
      }
      if (p.minNumber) {
        validator.push(Validators.min(p.minNumber));
      }
      if (p.isReq) {
        validator.push(Validators.required);
      }
      if (p.isEmail) {
        validator.push(Validators.email);
      }
      if (p.isDate) {
        validator.push(CustomValidator.validator(ValidatorEnum.DATEPATTERN));
      }
      if (p.isDateOrZero) {
        validator.push(CustomValidator.validator(ValidatorEnum.DATEPATTERNORZERO));
      }
      if (p.dateRange) {
        validator.push(CustomValidator.validator(ValidatorEnum.DATERANGE, p.dateRange));
      }
      if (p.isTime) {
        validator.push(CustomValidator.validator(ValidatorEnum.TIMEPATTERN, p.isTime));
      }
      if (p.isINN) {
        validator.push(CustomValidator.validator(ValidatorEnum.INN, p.isINN));
      }
      if (p.isOKPO) {
        validator.push(CustomValidator.validator(ValidatorEnum.OKPO));
      }
      if (p.isOGRN) {
        validator.push(CustomValidator.validator(ValidatorEnum.OGRN));
      }
      if (p.isSNILS) {
        validator.push(CustomValidator.validator(ValidatorEnum.SNILS));
      }
      if (p.isOKTMO) {
        validator.push(CustomValidator.validator(ValidatorEnum.OKTMO));
      }
      if (p.isBIKKO) {
        validator.push(CustomValidator.validator(ValidatorEnum.BIKKO));
      }
      if (p.isPolicyOMC) {
        validator.push(CustomValidator.validator(ValidatorEnum.PolicyOMC));
      }
      if (p.isPolicyOMC9or16) {
        validator.push(CustomValidator.validator(ValidatorEnum.PolicyOMC9or16));
      }
      if (p.isInnKio5or10) {
        validator.push(CustomValidator.validator(ValidatorEnum.InnKio5or10));
      }
      if (p.isIdentifikatorPD28or30) {
        validator.push(CustomValidator.validator(ValidatorEnum.IdentifikatorPD28or30));
      }
      if (p.isRegion) {
        validator.push(CustomValidator.validator(ValidatorEnum.REGION));
      }
      if (p.isCorrID) {
        validator.push(CustomValidator.validator(ValidatorEnum.CORRID));
      }
      if (p.pattern) {
        validator.push(Validators.pattern(p.pattern));
      }
      if (p.extendedValidation) {
        validator.push(...p.extendedValidation);
      }
      if (p.isSwift2or8or11) {
        validator.push(CustomValidator.validator(ValidatorEnum.Swift2or8or11))
      }
      if (validator.length === 0) {
        // validator.push(null);
      }
    }
    return validator;
  }

  isControlReq(control): boolean{
    return control?.validator && control.validator({} as AbstractControl)?.required;
  }

  arrayItemLengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const condition = control?.value?.every(value => value.length == 1);
      return !condition ? {pattern: true} : null;
    };
  }

  public is5392Subject$ = new BehaviorSubject<boolean>(false);
}

import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs";

@Component({
    selector: "app-modal-add-cert",
    template: `
        <div class="modal-header">
            <h4 class="modal-title" id="modal-title">Добавление ключа шифрования</h4>
        </div>
        <ng-container *ngIf="!loading && certificatesItems.length != 0">
            <form
                class="modal-body"
                *ngFor="let certificate of certificatesItems; index as id"
                [formGroup]="formGroup">
                <div class="aml-checkbox-form">
                    <label class="aml-checkbox">
                        <input
                            type="radio"
                            name="cproKey"
                            [formControl]="cproKeyControl"
                            [value]="certificate"
                            [id]="id" />
                        <span class="success"></span>
                    </label>
                    <div class="aml-checkbox-text">
                        <label [for]="id">{{ certificate?.subjectName }}</label>
                    </div>
                </div>
            </form>
        </ng-container>

        <ng-container *ngIf="!loading && certificatesItems.length === 0">
            <div class="modal-body">
                Нет доступных сертификатов
            </div>
        </ng-container>

        <ng-container *ngIf="loading">
            <div class="modal-body">
                <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
                Идет загрузка...
            </div>
        </ng-container>
        <div class="modal-footer">
            <button
                type="button"
                [disabled]="!cproKeyControl?.value"
                ngbAutofocus
                class="btn btn-outline-secondary"
                (click)="modal.close({cert: cproKeyControl?.value})">
                Ок
            </button>
            <button type="button" class="btn btn-outline-secondary" (click)="modal.close(false)">
                Отмена
            </button>
        </div>
    `,
})
export class AddCertModalComponent implements OnInit {
    @Input() cproKeys$;

    certificatesItems = [];
    loading = true;
    formGroup: FormGroup;

    constructor(public modal: NgbActiveModal) {
        this.formGroup = new FormGroup({ cproKeyControl: new FormControl() });
    }

    ngOnInit(): void {
        this.cproKeys$.pipe(first()).subscribe((data) => {
            this.certificatesItems = data;
            this.loading = false;
        });
    }

    get cproKeyControl() {
        return this.formGroup.get("cproKeyControl");
    }
}
